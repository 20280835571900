<template>
  <div class="main">
    <base-header></base-header>
    <div class="main-box">
      <router-view />
    </div>
    <base-footer></base-footer>
  </div>
</template>

<script>
import baseHeader from '@/components/base/base-header.vue'
import baseFooter from '@/components/base/base-footer.vue'
export default {
  name: 'Login',
  components: {
    baseHeader,
    baseFooter,
  },
  data() {
    return {
      ruleForm: {},
      isAgreement: false,
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.main-box {
  background-color: #f6f6f6;
  padding-top: 72px;
}
</style>
