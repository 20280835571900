<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-top-cont flex-start">
        <div class="company">
          <div class="logo">
            <img class="loginImg" src="@/assets/img/home/footerText.png" />
          </div>
          <!-- <div class="name">
            <img class="loginImg" src="@/assets/img/home/footerLogo.png" />
          </div> -->
          <div class="tip">
            {{ $t('footer_2') }}
          </div>
          <div class="tip">
            Hong Kong | Cape Town | New York | London | Sydney | Tokyo | Dublin
          </div>
        </div>
        <div class="footer-item">
          <div class="tr">{{ $t('footer_3') }}</div>
          <div class="th" @click="jumpTo('Account')">{{ $t('footer_4') }}</div>
          <div class="th" @click="jumpTo('Payment')">{{ $t('footer_5') }}</div>
          <div class="th" @click="jumpTo('Card')">Metabank</div>
          <div class="th" @click="jumpTo('Exchange')">{{ $t('footer_6') }}</div>
        </div>
        <div class="footer-item resource">
          <div class="tr">{{ $t('footer_11') }}</div>
          <div class="th" @click="jumpTo('FAQ')">{{ $t('footer_7') }}</div>
          <div class="th" @click="jumpTo('Contact')">{{ $t('footer_8') }}</div>
        </div>
        <div class="footer-item companys">
          <div class="tr">{{ $t('footer_12') }}</div>
          <div class="th" @click="jumpTo('About')">{{ $t('footer_9') }}</div>
          <div class="th" @click="jumpTo('PrivacyPolicy')">
            {{ $t('footer_10') }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <!-- <div class="bottom-tip">
        {{ $t('footer_13') }}
      </div> -->
      <div class="bottom-tip">2022 © Metabank Limited</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      langValue: '',
    }
  },
  methods: {
    jumpTo(pageName) {
      this.$router.push({ name: pageName })
    },
  },
}
</script>

<style scoped lang="scss">
.footer-top {
  height: 200px;
  background: #08080b;

  .footer-top-cont {
    width: 1100px;
    margin: 0 auto;

    .company {
      width: 400px;
      color: #ffffff;
      margin-right: 130px;
      text-align: center;

      .logo {
        margin-top: 36px;
        margin-bottom: 20px;
        img {
          width: 360px;
        }
      }

      .name {
        margin: 21px 0;
        img {
          width: 130px;
        }
      }

      .tip {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .footer-item {
      width: 166px;
      margin-top: 16px;
      border-right: 1px solid #333333;
      height: 180px;

      &.resource {
        margin-left: 100px;
      }

      &.companys {
        width: 50px;
        border: 0;
        margin-left: 100px;
      }

      .tr {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 24px;
      }

      .th {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}

.footer-bottom {
  height: 26px;
  background: #333333;
  text-align: center;
  line-height: 26px;
  .bottom-tip {
    font-size: 10px;
    color: #999999;
  }
}
</style>
