<template>
  <div class="herderBase">
    <div class="mainBox flex-between">
      <div class="logo">
        <img class="logoImg" src="@/assets/img/logo.png" />
      </div>
      <div class="nav">
        <div class="nav-list flex-start">
          <div
            @click="toUrl('Home')"
            class="item"
            :class="{ active: $route.name === 'Home' && type != 2 }"
          >
            {{ $t('header_1') }}
          </div>
          <div
            @click="toRemittance"
            class="item"
            :class="{ active: $route.name === 'Home' && type === 2 }"
          >
            {{ $t('header_2') }}
          </div>
          <div
            class="item"
            @click="toUrl('About')"
            :class="{ active: $route.name === 'About' }"
          >
            {{ $t('header_3') }}
          </div>
          <div
            class="item"
            @click="toUrl('FAQ')"
            :class="{ active: $route.name === 'FAQ' }"
          >
            {{ $t('header_4') }}
          </div>
        </div>
      </div>
      <div class="lang">
        <template v-if="!email">
          <el-button type="text" @click="toUrl('Login')">
            {{ $t('header_5') }}
          </el-button>
          <el-button class="reg" type="text" @click="toUrl('Email')">
            {{ $t('header_6') }}
          </el-button>
        </template>
        <template v-else>
          <el-button class="email" type="text" @click="toUrl('My')">
            {{ email }}
          </el-button>
          <el-button class="email" type="text" @click="logout()">
            {{ $t('header_7') }}
          </el-button>
        </template>
        <el-dropdown @command="langChange">
          <span class="el-dropdown-link">
            <img class="lang" src="@/assets/img/home/lang.png" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="cn">
              <span :class="langValue === 'cn' ? 'langActve' : ''"
                >简体中文</span
              >
            </el-dropdown-item>
            <el-dropdown-item command="zhTW">
              <span :class="langValue === 'zhTW' ? 'langActve' : ''"
                >繁体中文</span
              >
            </el-dropdown-item>
            <el-dropdown-item command="en">
              <span :class="langValue === 'en' ? 'langActve' : ''"
                >English</span
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { loginout } from '@/utils/tool'
export default {
  name: 'Header',
  data() {
    return {
      langValue: '',
      type: 1,
      email: '',
    }
  },
  created() {
    this.langValue = localStorage.lang == undefined ? 'cn' : localStorage.lang
    let email = localStorage.getItem('sm_email')
    if (email) {
      var disLength = email.length
      this.email =
        email.substring(0, 4) +
        '***' +
        email.substring(disLength - 8, disLength)
    }
  },
  methods: {
    toRemittance() {
      this.$router.push({ name: 'Home' })
      this.type = 2
      this.$nextTick(() => {
        this.$root.eventHub.$emit('anchorScroll')
      })
    },
    //语言切换
    langChange(e) {
      this.langValue = e
      localStorage.setItem('lang', e)
      this.$i18n.locale = e
    },
    toUrl(name) {
      this.type = 1
      this.$router.push({ name: name })
    },
    logout() {
      this.$confirm(this.$t('确定要退出登录吗?'), this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning',
      })
        .then(() => {
          loginout()
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.herderBase {
  height: 72px;
  background: rgba(255, 255, 255, 0.88);
  position: fixed;
  width: 100%;
  z-index: 1000;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: inherit;
    filter: blur(24px);
    z-index: 1001;
  }

  .mainBox {
    height: 72px;
    align-items: center;
    position: relative;
    z-index: 1002;
  }
  .logo {
    display: flex;
    align-items: center;
    .logoImg {
      width: 140px;
    }
  }
}

.nav-list {
  .item {
    height: 40px;
    line-height: 40px;
    // background: #fff;
    border-radius: 4px;
    padding: 0 24px;
    margin-right: 2px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    cursor: pointer;

    &:hover {
      color: #409eff;
    }

    &.active {
      background: #409eff;
      color: #fff;
    }
  }
}

.lang {
  display: flex;
  align-items: center;

  .el-button {
    font-size: 14px;
  }

  .reg {
    color: #666666;
    margin-left: 10px;
  }

  .email {
    //margin-right: 10px;
  }

  .lang {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.langActve {
  color: #409eff;
}
</style>
